<template>
	<v-layout fill-height column>
		<v-flex shrink>
			<v-layout row justify-end align-center>
				<v-tooltip bottom>
					<template v-slot:activator="{ on }">
						<v-btn flat round small color="primary" v-on="on" @click="addSubTask">
							<v-icon>add</v-icon>
							<span v-t="'client_workspace.task.add'" class="mx-2" />
						</v-btn>
					</template>
					<span v-t="'client_workspace.task.create.accountingFirmTask'" />
				</v-tooltip>
			</v-layout>
		</v-flex>
		<v-flex fill-height scroll-y>
			<v-list>
				<v-list-tile v-for="subTask in subTasks" :key="subTask.id" @click="subTask.id ? select(subTask) : null">
					<TasksManagerTask :value="subTask" class="pointer" />
				</v-list-tile>
			</v-list>
		</v-flex>
	</v-layout>
</template>

<script>
import TasksManagerModuleGuard from '@/mixins/ModulesGuards/TasksManager/TasksManagerModuleGuard'

export default {
	name: 'TasksManagerTaskDetailsSubtaskList',
	components: {
		TasksManagerTask: () => ({
			component: import('@/components/TasksManager/TasksManagerTask')
		})
	},
	mixins: [TasksManagerModuleGuard],
	props: {
		task: {
			required: true,
			type: Object
		}
	},
	data: function () {
		return {
			subTasks: []
		}
	},
	watch: {
		task: {
			deep: true,
			handler: function () {
				this.subTasks = [...this.task.children.filter(this.service.isOfTaskAccountingFirmType)]
			}
		}
	},
	created: function () {
		this.subTasks = [...this.task.children.filter(this.service.isOfTaskAccountingFirmType)]
	},
	methods: {
		select: function (subtask) {
			this.service.selectTask(subtask)
		},
		addSubTask: function () {
			const subTask = {
				title: '',
				description: '',
				note: '',
				vendor: this.task.vendor,
				members: this.task.members.map(member => member.id),
				parent: this.task,
				themes: this.task.themes.map(theme => theme.id),
				status: this.service.getFirstTaskStatus(),
				type: this.service.getTaskAccountingFirmType()
			}
			this.subTasks.unshift(subTask)
		}
	}
}
</script>
